import React, { Fragment } from "react"

export default class FileWrapper extends React.Component {
  fileRef = React.createRef()
  formRef = React.createRef()

  reset = () => {
    this.formRef.current.reset()
  }

  onChange = evt => {
    const { onFileChange } = this.props
    if (evt.target.files.length !== 0) {
      // Array.from is not supported by IE11
      // onFileChange && onFileChange(Array.from(evt.target.files), this.reset)
      const files = []
      for (let i = 0; i < evt.target.files.length; i++) {
        files.push(evt.target.files[i])
      }
      onFileChange && onFileChange(files, this.reset)
    } else {
      onFileChange && onFileChange([], this.reset)
    }
  }

  render() {
    const { multiple, children } = this.props
    const childrenWithProps = React.Children.map(children, child => {
      const clonedChild = React.cloneElement(child, {
        onClick: () => {
          child.props.onClick && child.props.onClick()
          this.fileRef.current.click()
        },
      })
      return clonedChild
    })

    return (
      <Fragment>
        {childrenWithProps}
        <form ref={this.formRef}>
          <input
            type="file"
            className="hidden"
            ref={this.fileRef}
            onChange={this.onChange}
            multiple={multiple}
          />
        </form>
      </Fragment>
    )
  }
}
