import axios from "axios"

export const server =
  "http://ec2-52-68-203-235.ap-northeast-1.compute.amazonaws.com"

export const nodeServer = `${server}:8701`

let headers = null
let user = null

const limit = 5000

export const getUserRole = () => {
  if (typeof sessionStorage === "undefined" || !sessionStorage.user) {
    return 0
  }
  if (!headers) {
    user = JSON.parse(sessionStorage.user)
    headers = { Authorization: `Bearer ${user.jwt}` }
  }
  return user.user.role.id
}

export const getUserData = () => {
  if (getUserRole() === 0) return null
  return user.user
}

const endpoints = {
  news: "news",
  associators: "associators",
  activities: "activities",
  users: "users",
  roles: "users-permissions/roles",
  announcements: "announcements",
  markdowns: "markdowns",
}

const catchAll = e => {
  console.error(e)
  throw e
}

const parametrize = params => {
  if (!params) return ""
  const entries = Object.entries(params)
  return entries.reduce((query, [k, v], index) => {
    query += `${k}=${v}`
    if (index !== entries.length - 1) {
      query += "&"
    }
    return query
  }, "?")
}

const request = {
  login(username, password) {
    return axios
      .post(`${server}/auth/local`, {
        identifier: username,
        password: password,
      })
      .then(res => {
        sessionStorage.user = JSON.stringify(res.data)
        return res
      })
      .catch(catchAll)
  },
  logout() {
    delete sessionStorage.user
    headers = null
  },
  getPublishStatus() {
    return axios.get(`${nodeServer}/publish`).catch(catchAll)
  },
  postPublish() {
    return axios
      .post(`${nodeServer}/publish`, { jwt: user.jwt })
      .catch(catchAll)
  },
  getMedia(params) {
    if (!getUserRole())
      return Promise.reject(new Error("you need to login first"))
    return axios
      .get(`${server}/upload/files${parametrize(params)}`, { headers })
      .catch(catchAll)
  },
  postMedia(formData, onProgress) {
    if (!getUserRole())
      return Promise.reject(new Error("you need to login first"))
    return axios
      .post(`${server}/upload`, formData, {
        headers,
        "Content-Type": "multipart/form-data",
        onUploadProgress: function(progressEvent) {
          if (onProgress) {
            let percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            )
            onProgress({
              loaded: progressEvent.loaded,
              total: progressEvent.total,
              percentage: percentCompleted,
            })
          }
        },
      })
      .catch(catchAll)
  },
  deleteMedia(...ids) {
    if (!getUserRole())
      return Promise.reject(new Error("you need to login first"))
    const promises = []
    ids.forEach(id => {
      promises.push(axios.delete(`${server}/upload/files/${id}`, { headers }))
    })
    return Promise.all(promises).catch(catchAll)
  },
  get(endpoint, params = {}) {
    const querystring = parametrize({
      ...params,
      _limit: limit,
    })
    if (!getUserRole())
      return Promise.reject(new Error("you need to login first"))
    return axios
      .get(`${server}/${endpoint}${querystring}`, { headers })
      .catch(catchAll)
  },
  post(endpoint, data) {
    if (!getUserRole())
      return Promise.reject(new Error("you need to login first"))
    return axios
      .post(`${server}/${endpoint}`, data, { headers })
      .catch(catchAll)
  },
  put(endpoint, id, data) {
    if (!getUserRole())
      return Promise.reject(new Error("you need to login first"))
    return axios
      .put(`${server}/${endpoint}/${id}`, data, { headers })
      .catch(catchAll)
  },
  delete(endpoint, id) {
    if (!getUserRole())
      return Promise.reject(new Error("you need to login first"))
    return axios
      .delete(`${server}/${endpoint}/${id}`, { headers })
      .catch(catchAll)
  },
  getNews(params) {
    return this.get(endpoints.news, params)
  },
  postNews(news) {
    return this.post(endpoints.news, news)
  },
  putNews(id, news) {
    return this.put(endpoints.news, id, news)
  },
  deleteNews(id) {
    return this.delete(endpoints.news, id)
  },
  getAssociators() {
    return this.get(endpoints.associators)
  },
  postAssociator(associator) {
    return this.post(endpoints.associators, associator)
  },
  putAssociator(id, associator) {
    return this.put(endpoints.associators, id, associator)
  },
  deleteAssociator(id) {
    return this.delete(endpoints.associators, id)
  },
  getActivities(params) {
    return this.get(endpoints.activities, params)
  },
  postActivity(activity) {
    return this.post(endpoints.activities, activity)
  },
  putActivity(id, data) {
    return this.put(endpoints.activities, id, data)
  },
  deleteActivity(activity) {
    return this.delete(endpoints.activities, activity.id).then(() =>
      this.deleteMedia(activity.image.id)
    )
  },
  getRoles() {
    return this.get(endpoints.roles)
  },
  getUsers() {
    return this.get(endpoints.users)
  },
  postUser(user) {
    return this.post(endpoints.users, user)
  },
  putUser(id, data) {
    return this.put(endpoints.users, id, data)
  },
  deleteUser(id) {
    return this.delete(endpoints.users, id)
  },
  getAnnouncements(params) {
    return this.get(endpoints.announcements, params)
  },
  postAnnouncement(announcement) {
    return this.post(endpoints.announcements, announcement)
  },
  putAnnouncement(id, data) {
    return this.put(endpoints.announcements, id, data)
  },
  deleteAnnouncement(id) {
    return this.delete(endpoints.announcements, id)
  },
  getMarkdowns() {
    return axios.get(`${nodeServer}/markdowns`).catch(catchAll)
  },
  getMarkdown(index) {
    return axios.get(`${nodeServer}/markdowns/${index}`).catch(catchAll)
  },
  putMarkdown(index, content) {
    return axios.put(`${nodeServer}/markdowns/${index}`, {
      jwt: user.jwt,
      content,
    })
  },
}

export default request
