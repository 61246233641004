import React from "react"
import ReactDOM from "react-dom"

export default class Dropdown extends React.Component {
  state = {
    search: "",
    toggled: false,
  }

  node = null

  clickAway = e => {
    this.node = this.node || ReactDOM.findDOMNode(this)
    if (!this.node.contains(e.target)) this.setState({ toggled: false })
  }

  toggleMenu = e => {
    e.preventDefault()
    this.setState({ toggled: !this.state.toggled })
  }
  onChange = e => this.setState({ search: e.target.value })

  componentDidMount() {
    document.body.addEventListener("click", this.clickAway)
  }

  componentWillUnmount() {
    document.body.removeEventListener("click", this.clickAway)
  }

  render() {
    const { search, toggled } = this.state
    const { label, nameKey, items, onSelect, className } = this.props
    const filteredItems = items.filter(item => item[nameKey].includes(search))
    return (
      <div className={`dropdown ${className}`}>
        <button className="dropdown__label" onClick={this.toggleMenu}>
          {label}
        </button>
        <div
          className={`dropdown__menu ${
            toggled ? "dropdown__menu--toggled" : ""
          }`}
        >
          <input
            className="dropdown__search"
            type="text"
            value={search}
            placeholder="依名稱進行搜尋"
            onChange={this.onChange}
          />
          <div className="dropdown__content">
            {filteredItems.map((item, index) => (
              <div
                className="dropdown__item"
                key={item.id || index}
                onClick={() => {
                  onSelect && onSelect(item)
                  this.setState({ toggled: false })
                }}
              >
                {item[nameKey]}
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
}
