import React from "react"
import ReactDOM from "react-dom"

export default class Modal extends React.Component {
  div = document.createElement("div")
  state = {
    overflow: false,
  }
  contentRef = React.createRef()
  vh = 0

  escFunction = event => {
    const { onCancel } = this.props
    if (event.keyCode === 27) {
      onCancel && onCancel()
    }
  }

  componentDidMount() {
    document.getElementById("modal").appendChild(this.div)
    // https://stackoverflow.com/questions/1248081/get-the-browser-viewport-dimensions-with-javascript
    this.vh = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0
    )
    document.addEventListener("keydown", this.escFunction, false)
  }

  componentWillUnmount() {
    document.getElementById("modal").removeChild(this.div)
    document.removeEventListener("keydown", this.escFunction, false)
  }

  componentDidUpdate() {
    const h = this.contentRef.current.getBoundingClientRect().height
    const overflow = this.vh < h
    if (this.state.overflow !== overflow) this.setState({ overflow })
  }

  render() {
    const {
      children,
      reveal,
      size = "md",
      header,
      onConfirm,
      onCancel,
      textConfirm = "確定",
      textCancel = "取消",
      noShadow = false,
      clickAway = false,
    } = this.props
    const { overflow } = this.state
    if (reveal) {
      document.body.classList.add("no-scroll")
    } else {
      document.body.classList.remove("no-scroll")
    }
    return ReactDOM.createPortal(
      <div className={"modal " + (reveal ? "modal--reveal" : "")}>
        <div
          className="modal__backdrop"
          onClick={e => {
            if (clickAway && !this.contentRef.current.contains(e.target)) {
              onCancel && onCancel()
            }
          }}
        >
          <div
            className={`modal__content modal__content--${size} ${
              overflow ? "modal__content--overflow" : ""
            } ${noShadow ? "modal__content--no-shadow" : ""}`}
            ref={this.contentRef}
          >
            {header && <div className="modal__header">{header}</div>}
            <div className="modal__body">{children}</div>
            {((onCancel && textCancel) || onConfirm) && (
              <div className="modal__footer">
                {onCancel && <button onClick={onCancel}>{textCancel}</button>}
                {onConfirm && (
                  <button onClick={onConfirm}>{textConfirm}</button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>,
      this.div
    )
  }
}
