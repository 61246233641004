import React from "react"
import { navigate } from "gatsby"

import Modal from "../../components/common/modal"
import request, { getUserRole, getUserData } from "../../request"

class Layout extends React.Component {
  state = {
    isPublishing: false,
    isAdmin: false,
    reveal: false,
    userData: {},
    name: "",
    phone: "",
    password: "",
  }

  componentDidMount() {
    request.getPublishStatus().then(res => {
      this.setState({
        isPublishing: res.data.isPublishing,
        isAdmin: getUserRole() === 1,
        userData: getUserData(),
      })
    })
  }

  publish = () => {
    this.setState({ isPublishing: true })
    request
      .postPublish()
      .then(res => {
        if (res.data.error) {
          alert("網站更新發布失敗!!!")
        } else {
          alert("網站更新發布完成")
        }
        this.setState({ isPublishing: false })
      })
      .catch(() => {
        alert("網站更新發布失敗!!!")
        this.setState({ isPublishing: false })
      })
  }

  updateProfile = () => {
    const { userData } = this.state
    this.setState({
      reveal: true,
      name: userData.name || "",
      phone: userData.phone || "",
      password: "",
    })
  }

  onKeyDown = e => {
    if (e.keyCode === 13) {
      this.onConfirm()
    }
  }

  onConfirm = () => {
    const { userData, name, phone, password } = this.state
    if (!name || !phone) {
      return alert("姓名與電話為必填欄位")
    }
    const data = { name, phone }
    if (password) {
      data["password"] = password
    }
    // eslint-disable-next-line no-restricted-globals
    if (confirm("更新個人資訊後需重新登入,確定要更新?")) {
      request.putUser(userData.id, data).then(() => {
        request.logout()
        navigate("/admin")
      })
    }
  }

  onCancel = () => {
    this.setState({ reveal: false })
  }

  render() {
    const { isPublishing, isAdmin, reveal, name, phone, password } = this.state
    const { children } = this.props
    if (typeof window === "undefined") return null
    const { pathname } = window.location

    return (
      <div className="admin__wrapper">
        <div className="ad-layout__header">
          <div className="ad-layout__logout-btn">
            <button
              className={isAdmin ? "" : "hidden"}
              onClick={() => {
                // eslint-disable-next-line no-restricted-globals
                if (confirm("確定要發布網站更新?")) {
                  this.publish()
                }
              }}
              disabled={isPublishing}
            >
              {isPublishing ? "網站更新發布中..." : "發布網站更新"}
            </button>
            {/* <button onClick={this.updateProfile}>個人資訊</button> */}
            <button
              onClick={() => {
                request.logout()
                navigate("/admin")
              }}
            >
              登出
            </button>
          </div>
        </div>
        <div className="ad-layout__wrapper">
          <div className="ad-layout__sidebar">
            <div
              className={
                `${isAdmin ? "" : "hidden"} ` +
                "ad-layout__sidebar-item " +
                (pathname === "/admin/news"
                  ? "ad-layout__sidebar-item--active"
                  : "")
              }
              onClick={() => navigate("/admin/news")}
            >
              最新消息
            </div>
            <div
              className={
                `${isAdmin ? "" : "hidden"} ` +
                "ad-layout__sidebar-item " +
                (pathname === "/admin/download"
                  ? "ad-layout__sidebar-item--active"
                  : "")
              }
              onClick={() => navigate("/admin/download")}
            >
              檔案下載
            </div>
            <div
              className={
                `${isAdmin ? "" : "hidden"} ` +
                "ad-layout__sidebar-item " +
                (pathname === "/admin/course"
                  ? "ad-layout__sidebar-item--active"
                  : "")
              }
              onClick={() => navigate("/admin/course")}
            >
              相關課程
            </div>
            <div
              className={
                `${isAdmin ? "" : "hidden"} ` +
                "ad-layout__sidebar-item " +
                (pathname === "/admin/markdown"
                  ? "ad-layout__sidebar-item--active"
                  : "")
              }
              onClick={() => navigate("/admin/markdown")}
            >
              文字內容編輯
            </div>
            <div
              className={
                `${isAdmin ? "" : "hidden"} ` +
                "ad-layout__sidebar-item " +
                (pathname === "/admin/associator"
                  ? "ad-layout__sidebar-item--active"
                  : "")
              }
              onClick={() => navigate("/admin/associator")}
            >
              會員名錄
            </div>
            <div
              className={
                `${isAdmin ? "" : "hidden"} ` +
                "ad-layout__sidebar-item " +
                (pathname === "/admin/activity"
                  ? "ad-layout__sidebar-item--active"
                  : "")
              }
              onClick={() => navigate("/admin/activity")}
            >
              活動花絮
            </div>
            <div
              className={
                `${isAdmin ? "" : "hidden"} ` +
                "ad-layout__sidebar-item " +
                (pathname === "/admin/user"
                  ? "ad-layout__sidebar-item--active"
                  : "")
              }
              onClick={() => navigate("/admin/user")}
            >
              留言板使用者管理
            </div>
            <div
              className={
                "ad-layout__sidebar-item " +
                (pathname === "/admin/announcement"
                  ? "ad-layout__sidebar-item--active"
                  : "")
              }
              onClick={() => navigate("/admin/announcement")}
            >
              留言板
            </div>
          </div>
          <div className="ad-layout__main">{children}</div>
        </div>
        <Modal
          reveal={reveal}
          size="md"
          header="個人資訊"
          onCancel={this.onCancel}
          onConfirm={this.onConfirm}
          textConfirm="更新"
        >
          <div className="ad-user__modal">
            <form className="ad-user__form">
              <div className="ad-user__form-item">
                <div className="ad-user__form-text">姓名</div>
                <input
                  className="ad-user__form-input"
                  type="text"
                  placeholder="例:王大明"
                  value={name}
                  onKeyDown={this.onKeyDown}
                  onChange={e => this.setState({ name: e.target.value })}
                />
              </div>
              <div className="ad-user__form-item">
                <div className="ad-user__form-text">電話</div>
                <input
                  className="ad-user__form-input"
                  type="text"
                  placeholder="例:0912345678"
                  value={phone}
                  onKeyDown={this.onKeyDown}
                  onChange={e => this.setState({ phone: e.target.value })}
                />
              </div>
              <div className="ad-user__form-item">
                <div className="ad-user__form-text">更新密碼</div>
                <input
                  className="ad-user__form-input"
                  type="password"
                  autoComplete="no"
                  value={password}
                  onKeyDown={this.onKeyDown}
                  placeholder="若無需修改密碼請留空白"
                  onChange={e => this.setState({ password: e.target.value })}
                />
              </div>
            </form>
          </div>
        </Modal>
      </div>
    )
  }
}

export default Layout
